<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
		>
			<el-table-column prop="id" label="id" min-width="10%"> </el-table-column>
			<el-table-column prop="title" label="标题" min-width="10%">
			</el-table-column>
			<el-table-column prop="desc" label="描述内容" min-width="10%">
			</el-table-column>
			<el-table-column prop="object_id" label="文章id" min-width="10%">
			</el-table-column>
			<el-table-column prop="add_time" label="时间" min-width="10%">
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { statusReport } from "@/api/information";
export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {
		toDetail(row) {
			this.$router.push({
				path: "/reportinfodetail",
				query: { id: row.id },
			});
		},
	},
};
</script>

<style scoped></style>
